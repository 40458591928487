<template>
  <div
    class="w-6/12 pt-4 pb-5 mr-2 mb-4 bg-white border border-primary rounded-br-xl font-primaryLight relative"
  >
    <!--  <RefreshIcon
      iconColor="#fff"
      class="p-1 bg-primary rounded-full absolute cursor-pointer top-2 right-2"
    /> -->
    <h3 class="pb-4 text-xl">Nutrition Plan</h3>
    <ul
      class="px-4 mb-2 text-sm flex items-center justify-between"
      v-for="(item, i) in data"
      :key="i"
    >
      <li>{{ item.feeding_times + " x " + item.feeding_frequency }}</li>
      <li>{{ item.plan_name }}</li>
      <li>{{ item.feeding_amount + " " + item.measurement }}</li>
    </ul>
  </div>
</template>

<script>
import RefreshIcon from "../../icons/RefreshIcon.vue";

export default {
  props: ["data"],
  components: {
    RefreshIcon,
  },
};
</script>

<style>
</style>