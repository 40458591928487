<template>
  <div
    class="w-6/12 pt-4 pb-3 mr-2 mb-4 bg-white border border-primary rounded-br-xl font-primaryLight relative"
  >
    <!-- <RefreshIcon
      iconColor="#fff"
      class="p-1 bg-primary rounded-full absolute cursor-pointer top-2 right-2"
    /> -->
    <h3 class="pb-4 text-xl">Diagnosis</h3>
    <ul
      class="px-4 mb-2 text-sm flex items-center justify-between"
      v-for="(item, i) in data"
      :key="i"
    >
      <li>{{ item.disease }}</li>
      <li>{{ item.diagnosis_clinic }}</li>
      <li>{{ item.date_diagnosis ? formatDate(item.date_diagnosis) : "" }}</li>
    </ul>
  </div>
</template>

<script>
import RefreshIcon from "../../icons/RefreshIcon.vue";

export default {
  props: ["data"],
  components: {
    RefreshIcon,
  },
  data() {
    return {};
  },
  methods: {
    formatDate(d) {
      return moment(d.substr(0, 10)).format("DD.MM.YYYY");
    },
  },
};
</script>

<style>
</style>