<template>
  <svg
    width="37"
    height="36"
    viewBox="0 0 37 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.8672 12.002V18.002L21.8672 21.002"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.44531 16.503C5.78145 13.2031 7.32071 10.1425 9.76941 7.90498C12.2181 5.66751 15.4049 4.40986 18.7216 4.37198C22.0384 4.33409 25.253 5.51863 27.7522 7.69958C30.2514 9.88054 31.8601 12.9052 32.2716 16.1966C32.683 19.488 31.8683 22.8156 29.9828 25.5447C28.0974 28.2737 25.2733 30.2131 22.0492 30.9928C18.8252 31.7725 15.4269 31.338 12.5028 29.7721C9.57869 28.2063 7.33339 25.6187 6.19531 22.503"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.44531 30.0039V22.5039H12.9453"
      :stroke="iconColor"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#354E57",
    },
  },
};
</script>