<template>
  <Transition name="modal">
    <div v-if="transcribeRecordingModalsComp" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container md:w-10/12 lg:w-6/12 2xl:w-5/12 flex flex-col justify-center items-center">
          <div class="w-10/12 modal-body mt-5 mb-0 text-proximaMedium text-subheaderText">
            <AVWaveform :audioSrc="audioUrl" :height="100" :noplayed-line-color="'gray'" :played-line-color="'orange'"
              :played-line-width="2" :noplayed-line-width="2" :cursorColor="'red'" ref="audioVisual" v-if="audioUrl"
              class="w-full m-auto"></AVWaveform>
            <img class="m-auto mb-2 cursor-pointer" src="@/assets/images/play-icon.png" alt="play-icon"
              @click="startRecording" v-if="!toggleRecBtn" />
            <img class="m-auto mb-2 cursor-pointer" src="@/assets/images/stop-icon.png" alt="stop-icon"
              @click="stopRecording" v-else />
            <h3 class="text-2xl font-medium">Transcribe Recording?</h3>
          </div>
          <div class="modal-footer w-full flex md:flex-row justify-center items-center">
            <div class="w-11/12 md:w-4/12 lg:w-4/12">
              <slot name="leftBtn">
                <BorderedButton class="md:mr-1" title="Redo" borderColor="border-mediumGrey" textCl="text-darkGrey"
                  paddingX="px-8" @click.native="removeRecording" v-if="audioUrl" />
                <BorderedButton class="md:mr-1" title="Cancel" borderColor="border-mediumGrey" textCl="text-darkGrey"
                  paddingX="px-8" @click.native="closeTranscribeRecordingModals" v-else />
              </slot>
            </div>
            <div class="w-11/12 md:w-4/12 lg:w-4/12">
              <slot name="rightBtn">
                <SubmitButton class="md:ml-1" title="Confirm" background="bg-success" paddingX="px-8"
                  @click.native="openPublishTranscriptModals" v-if="audioUrl" />
                <SubmitButton class="md:ml-1 cursor-not-allowed" title="Confirm" background="bg-lightGrey" paddingX="px-8"
                  v-else />
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import AVWaveform from "vue-audio-visual/src/components/AvWaveform";
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    show: Boolean,
  },
  components: {
    AVWaveform,
    SubmitButton,
    BorderedButton,
  },
  data() {
    return {
      recordedChunks: [],
      mediaRecorder: null,
      audioStream: null,
      audioUrl: null,
      toggleRecBtn: false,
    };
  },
  computed: {
    ...mapGetters({
      text: "medicalhistory/getText",
    }),
    transcribeRecordingModalsComp() {
      return this.$store.state.transcribeRecordingModalsState;
    },
  },
  methods: {
    ...mapActions({
      voiceToText: "medicalhistory/voiceToText",
    }),

    closeTranscribeRecordingModals() {

      this.$store.commit("transcribeRecordingModals", false);
    },
    openPublishTranscriptModals() {
      console.log(this.audioUrl);
      console.log('***', this.recordedChunks);
      this.voiceToText(this.audioUrl);

      //this.removeRecording();
    },
    startRecording() {
      this.toggleRecBtn = true;
      if (this.mediaRecorder && this.mediaRecorder.state === "recording") {
        console.log("Recording is already in progress.");
        return;
      }

      if (this.mediaRecorder && this.mediaRecorder.state === "inactive") {
        this.recordedChunks = [];
        this.audioUrl = null;
      }

      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          this.audioStream = stream;
          this.mediaRecorder = new MediaRecorder(stream);

          this.mediaRecorder.addEventListener("dataavailable", (event) => {
            if (event.data.size > 0) {
              this.recordedChunks.push(event.data);
            }
          });

          this.mediaRecorder.addEventListener("stop", () => {
            const audioBlob = new Blob(this.recordedChunks, {
              type: "audio/wav",
            });
            const file = new File([audioBlob], "test.wav", {
              type: audioBlob.type,
              lastModified: Date.now()
            });
            console.log(file,'***');
            this.audioUrl = URL.createObjectURL(audioBlob);
       
            window.open(this.audioUrl);
          });

          this.mediaRecorder.start();
        })
        .catch((error) => {
          console.log("Error accessing the microphone:", error);
        });
    },
    stopRecording() {
      this.toggleRecBtn = false;
      if (this.mediaRecorder && this.audioStream) {
        this.mediaRecorder.stop();
        this.audioStream.getTracks().forEach((track) => track.stop());
        console.log('media ...', this.audioUrl, "---", this.mediaRecorder, '----', this.audioStream);
      }
    },
    removeRecording() {
      this.recordedChunks = [];
      this.audioUrl = null;
    },
  },
};
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  // width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>