<template>
  <div
    class="w-full pt-4 pb-5 mr-2 mb-4 bg-lightBlue border border-primary rounded-br-xl font-primaryLight relative"
  >
    <!--  <RefreshIcon
      iconColor="#fff"
      class="p-1 bg-primary rounded-full absolute cursor-pointer top-2 right-2"
    /> -->
    <h3 class="pb-4 text-xl">Medical Files</h3>
    <ul
      class="bg-white px-4 py-1 mb-4 text-sm text-primary font-proximaMedium flex items-center justify-between"
    >
      <li class="w-3/12">File Name</li>
      <li class="w-3/12">File Type</li>
      <li class="w-2/12">Date</li>
      <li class="w-1/12">Size</li>
      <li class="w-1/12">Approved</li>
      <li class="w-2/12">Sharing</li>
    </ul>
    <ul
      class="px-4 pb-2 mb-2 text-sm flex items-center border-b border-primary justify-between"
      v-for="(item, i) in data"
      :key="i"
    >
      <li class="w-3/12">{{ item.name }}</li>
      <li class="w-3/12">{{ item.type }}</li>
      <li class="w-2/12">
        {{ item.date_exam ? formatDate(item.date_exam) : "" }}
      </li>
      <li class="w-1/12">{{ item.size }}</li>
      <li class="w-1/12"></li>
      <li class="w-2/12">{{ item.is_public ? "public" : "private" }}</li>
    </ul>
  </div>
</template>

<script>
import RefreshIcon from "../../icons/RefreshIcon.vue";
import moment from "moment";

export default {
  props: ["data"],
  components: {
    RefreshIcon,
  },
  data() {
    return {};
  },
  methods: {
    formatDate(d) {
      return moment(d.substr(0, 10)).format("DD.MM.YYYY");
    },
  },
};
</script>

<style>
</style>