<template>
  <div>
    <div v-if="dashboard">
      <div class="flex justify-start items-center">
        <div class="md:w-6/12 flex justify-start">
          <img
            class="w-max h-32 ml-28 mt-0 hidden md:block"
            src="@/assets/images/dataservice-logo.svg"
            alt="dataservice-logo"
          />
        </div>
        <div class="md:w-5/12">
          <HeaderGlobal
            :titlemenu1="$t('account_dropdown_menu_pet_records')"
            :to1="toComp"
            :titlemenu2="title2"
            titlemenu3="Medical History"
            class="pl-4"
          />
        </div>
      </div>
      <div class="mt-12 flex flex-col items-center">
        <div class="w-10/12 flex items-start justify-between">
          <!-- <Diagnosis :data="dashboard.diagnosis" /> -->
          <div class="w-6/12 ml-2 flex flex-col items-start">
            <!-- <div class="my-2 flex items-center">
              <span>Interest Group</span>
              <RadioOnOff />
            </div>
            <div class="my-2 flex items-center">
              <span>Blood Donor</span>
              <RadioOnoffBorder
                :checked="checkedDonor"
                :val="donor"
                @inputVal="toggleRadio"
              />
            </div> -->
          </div>
        </div>
        <!--  <div class="w-10/12 flex items-start justify-between">
          <NutritionPlan :data="dashboard.nutrition" />
          <Vaccinations :data="dashboard.vaccination" />
        </div>-->
        <div class="w-10/12 mt-4">
          <MedicalFiles :data="medical_files" />
        </div>
      </div>

      <ModalsConfirm :show="bloodDonorModalsComp">
        <div slot="actionQuestion" class="relative">
          <div
            class="absolute -top-5 -right-5 text-2xl font-proximaSemibold cursor-pointer"
            @click="closeBloodDonorModals"
          >
            ✖
          </div>
          <h3 class="font-proximaMedium text-2xl font-bold text-subheaderText">
            Blood Donor Qualification
          </h3>
        </div>
        <div
          slot="actionText"
          class="w-full mt-10 flex flex-col items-center justify-center font-proximaMedium"
        >
          <div class="w-full flex items-center justify-between">
            <span class="text-sm"
              >Has your pet ever had a blood transfusion?</span
            >
            <div class="w-4/12">
              <InputRadio opt1="Yes" opt2="No" title="" />
            </div>
          </div>
          <div class="w-full flex items-center justify-between">
            <span class="text-sm"
              >Has your pet ever travelled to another country?</span
            >
            <div class="w-4/12">
              <InputRadio opt1="Yes" opt2="No" title="" />
            </div>
          </div>
          <div class="w-full flex items-center justify-between">
            <span class="text-sm"
              >Is your pet regularly dewormed and vaccinated?</span
            >
            <div class="w-4/12">
              <InputRadio opt1="Yes" opt2="No" title="" />
            </div>
          </div>
        </div>
        <div slot="leftBtn">
          <BorderedButton
            class="mr-1"
            :title="$t('global_cancel')"
            borderColor="border-darkGrey"
            textCl="text-darkGrey"
            paddingX="px-8"
            width="w-full mx-auto md:w-10/12"
            @click.native="closeBloodDonorModals"
          />
        </div>
        <div slot="rightBtn">
          <SubmitButton
            class="ml-1"
            title="Add"
            background="bg-success"
            paddingX="px-8"
            width="w-full mx-auto md:w-10/12"
            @click.native="submitBloodDonor"
          />
        </div>
      </ModalsConfirm>
      <SttStartModalsVue />
      <TranscribeRecordingModalsVue />
      <PublishTranscriptModalsVue />
    </div>
    <div v-else>
      <p>wailt ....</p>
    </div>
  </div>
</template>

<script>
import HeaderGlobal from "../header/HeaderGlobal.vue";
import Diagnosis from "./tables/Diagnosis.vue";
import NutritionPlan from "./tables/NutritionPlan.vue";
import Vaccinations from "./tables/Vaccinations.vue";
import MedicalFiles from "./tables/MedicalFiles.vue";
import RadioOnOff from "./RadioOnOff.vue";
import RadioOnoffBorder from "../../components/forms/RadioOnoffBorder.vue";
import InputRadio from "./InputRadio.vue";
import ModalsConfirm from "../modals/ModalsConfirm.vue";
import BorderedButton from "../buttons/BorderedButton.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import SttStartModalsVue from "./modals/SttStartModals.vue";
import TranscribeRecordingModalsVue from "./modals/TranscribeRecordingModals.vue";
import PublishTranscriptModalsVue from "./modals/PublishTranscriptModals.vue";

import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    HeaderGlobal,
    Diagnosis,
    NutritionPlan,
    Vaccinations,
    MedicalFiles,
    RadioOnOff,
    RadioOnoffBorder,
    InputRadio,
    ModalsConfirm,
    BorderedButton,
    SubmitButton,
    SttStartModalsVue,
    TranscribeRecordingModalsVue,
    PublishTranscriptModalsVue,
  },
  data() {
    return {
      bloodDonor: { name: "Blood Donor", id: 1 },
      pet: {},
      title2: "",
    };
  },
  computed: {
    ...mapGetters({
      dashboard: "medicalhistory/getDashboard",
    }),
    donor() {
      return this.dashboard.blood_donation
        ? ({
            id: this.dashboard.blood_donation.id,
            name: this.dashboard.blood_donation.donor,
          },
          console.log({
            id: this.dashboard.blood_donation.id,
            name: this.dashboard.blood_donation.donor,
          }))
        : { id: 0, name: "" };
    },
    toComp() {
      const role = localStorage.getItem("role");
      return role == 4 || role == 5 || role == 7
        ? "/sppassivedashboard/new"
        : "/petoverview/new";
    },
    checkedDonor() {
      return this.dashboard.blood_donation
        ? this.dashboard.blood_donation.donor
        : false;
    },
    medical_files() {
      if (localStorage.getItem("role") == 1)
        return this.dashboard.medical_files_owner;
      else return this.dashboard.medical_files_breeder;
    },
    bloodDonorModalsComp() {
      return this.$store.state.bloodDonorModalsState;
    },
  },

  created() {
    var retrievedObject = localStorage.getItem("pet");
    this.pet = JSON.parse(retrievedObject);

    this.getDashboard(this.pet.petID);

    this.title2 = this.pet
      ? this.pet.species == 1
        ? this.$t("global_dog") + " Record: " + this.pet.callName
        : this.pet.species == 2
        ? this.$t("global_cat") + " Record: " + this.pet.callName
        : this.pet.species == 3
        ? this.$t("global_ferret") + " Record: " + this.pet.callName
        : this.pet.species == 4
        ? this.$t("global_bird") + " Record: " + this.pet.callName
        : this.$t("global_other") + " Record: " + this.pet.callName
      : "";
  },

  methods: {
    ...mapActions({
      getDashboard: "medicalhistory/getDashboard",
      configureBloodDonation: "medicalhistory/configureBloodDonation",
    }),

    formatDate(d) {
      return moment(d.substr(0, 10)).format("DD.MM.YYYY");
    },
    toggleRadio(i, e) {
      console.log(e);
      this.$store.commit("bloodDonorModals", e);
      //this.bloodDonorModals = e;
    },
    closeBloodDonorModals() {
      this.$store.commit("bloodDonorModals", false);
    },
    openBloodDonorModals() {
      this.$store.commit("bloodDonorModals", true);
    },
    submitBloodDonor() {
      const data = {
        userEmailAddress: localStorage.getItem("email"),
        petID: this.pet.petID,
        donor: 1,
        blood_transfusion: 0,
        travelled_another_country: 1,
        regulary_dewormed_vaccinated: 0,
      };
      this.configureBloodDonation(data);
    },
  },
};
</script>

<style></style>
