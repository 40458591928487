<template>
  <div class="my-6">
    <MedicalSection />
    <NavMenuSecondary page="medicalhistorydashboard" />
  </div>
</template>

<script>
import MedicalSection from "../components/medicalhistorydashboard/MedicalSection.vue";
import NavMenuSecondary from "../components/navigationmenu/secondary/NavMenuSecondary.vue"

export default {
  components: {
    MedicalSection,
    NavMenuSecondary
  }
};
</script>