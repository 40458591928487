<template>
  <Transition name="modal">
    <div v-if="publishTranscriptModalsComp" class="modal-mask">
      <div class="modal-wrapper">
        <div
          class="modal-container md:w-10/12 lg:w-6/12 2xl:w-5/12 flex flex-col justify-center items-center"
        >
          <div class="w-10/12 modal-body mt-5 mb-0 font-proximaMedium">
            <h3 class="text-2xl font-medium text-subheaderText">Publish Transcript</h3>
            <div class="text-sm text-left">
              <p class="pt-4 font-proximaLight">
                This is a transcription of the recorded conversation. To publish
                it to your pet’s medical record and make it visible to other
                vets, the diagnosis clinic must approve.
              </p>
              <h5 class="pt-4 pb-2 text-subheaderText">Transcript1.TXT</h5>
              <p class="h-44 overflow-y-auto font-proximaLight">
                Today was a beautiful, bright and sunny day so I decided to
                build a boat! I needed to get some long, hard wood so I
                travelled to the forest to collect a huge pile. After some time,
                I began to get hungry. Today was a beautiful, bright and sunny
                day so I decided to build a boat! I needed to get some long,
                hard wood so I travelled to the forest to collect a huge pile.
                After some time, I began to get hungry. Today was a beautiful, bright and sunny day so I decided to
                build a boat! I needed to get some long, hard wood so I
                travelled to the forest to collect a huge pile. After some time,
                I began to get hungry. Today was a beautiful, bright and sunny
                day so I decided to build a boat! I needed to get some long,
                hard wood so I travelled to the forest to collect a huge pile.
                After some time, I began to get hungry.
              </p>
            </div>
            <div class="mt-4 flex items-center justify-between">
              <div class="w-6/12 mr-1 flex flex-col text-sm text-left">
                <label for="diagnosingClinic">Diagnosing Clinic:</label>
                <input
                  type="text"
                  name="diagnosingClinic"
                  class="p-2 rounded-sm bg-lightGrey20"
                  v-model="diagnosingClinic"
                  placeholder="Enter name of diagnosing clinic..."
                />
              </div>
              <div class="w-6/12 ml-1 flex flex-col text-sm text-left">
                <label for="clinicEmail">Clinic’s Email Address:</label>
                <input
                  type="text"
                  name="clinicEmail"
                  class="p-2 rounded-sm bg-lightGrey20"
                  v-model="clinicEmail"
                  placeholder="Enter clinic’s email address..."
                />
              </div>
            </div>
          </div>

          <div
            class="modal-footer w-full flex md:flex-row justify-center items-center"
          >
            <div class="w-11/12 md:w-4/12 lg:w-4/12">
              <slot name="leftBtn">
                <BorderedButton
                  class="md:mr-1"
                  :title="$t('global_cancel')"
                  borderColor="border-mediumGrey"
                  textCl="text-darkGrey"
                  paddingX="px-8"
                  @click.native="closePublishTranscriptModals"
                />
              </slot>
            </div>
            <div class="w-11/12 md:w-4/12 lg:w-4/12">
              <slot name="rightBtn">
                <SubmitButton
                  class="md:ml-1"
                  title="Confirm"
                  background="bg-success"
                  paddingX="px-8"
                />
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";

export default {
  props: {
    show: Boolean,
  },
  components: {
    SubmitButton,
    BorderedButton,
  },
  data() {
    return {
      diagnosingClinic: "",
      clinicEmail: "",
    };
  },
  computed: {
    publishTranscriptModalsComp() {
      return this.$store.state.publishTranscriptModalsState;
    },
  },
  methods: {
    closePublishTranscriptModals() {
      this.$store.commit("publishTranscriptModals", false);
    },
  },
};
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  // width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>