<template>
  <div class="font-proximaMedium w-full">
    <div class="w-full mt-3 flex flex-row lg:items-center">
      <label class="container w-full lg:w-6/12 text-sm text-left"
        >{{ opt1 }}
        <input
          type="radio"
          id="1"
          :value="1"
          v-model="picked"
          @change="$parent.$emit('blurred', 1)"
        />
        <!-- :disabled="!editable" -->
        <span class="checkmark"></span>
      </label>
      <label class="container w-full lg:w-6/12 text-sm text-left"
        >{{ opt2 }}
        <input type="radio" id="2" :value="2" v-model="picked" />
        <!-- :disabled="!editable" -->
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    opt1: {
      type: String,
      default: "Opt 1",
    },
    opt2: {
      type: String,
      default: "Opt 2",
    },
    val: {
      type: Number,
      default: 1,
    }
    /*   editable: {
      type: Boolean,
      default: "true",
    }, */
  },
  data: function () {
    return {
      picked: this.val,
    };
  },
  watch: {
    picked: function () {
      this.$emit("input", this.picked);
    },
  },
};
</script>

<style scoped>
/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid #9ca3af;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: transparent;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  border: none;
  background-color: #0ac0cb;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
</style>
